<template>
  <div>
    <!-- Cropfield name if add activity -->
    <div>
      <h4 class="subtitle-form">Parcelle</h4>
      <div v-if="this.entriesChecked.length > 1 && (this.currentActivity.cropField.length > 1 && !resetedCropfield)" class="info-container">
        <p class="text-reset">{{ $t("activities.multiple.edit.cropfield") }}</p>
      </div>
      <div v-else>
        <div v-if="this.errSelectCropField" class="text-activity-notes error-select-cropfield">
        Sélectionner d'abord un champ.
      </div>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        :options="this.cropFieldsUpdated"
        label="name_surface_cropName"
        :value="selectedCropfield"
        @input="selectedCropField => changeCropfield(selectedCropField.id)"
        required
      />
      </div>
    </div>

    <!-- Activity type -->
    <div>
      <h4 class="subtitle-form">Type d'activité</h4>
      <div v-if="this.entriesChecked.length > 1  && (this.currentActivity.activityType === undefined && !resetedActivity)" class="info-container">
        <p class="text-reset">{{ $t("activities.multiple.edit.activityType") }}</p>
        <RewriteButton :on-click="resetAllActivityType"/>
      </div>
      <div v-else >
        <div v-if="this.errActivityType" class="text-activity-notes error-select-cropfield">
        Sélectionner d'abord un champ.
      </div>
      <Dropdown
        ref="dropdown"
        class="shape-custom-select"
        :options="this.activityTypesFiltered"
        label="designation"
        :value="this.selectedActivityType"
        @input="selectedType => changeActivityType(selectedType.id)"
        required
      />
      </div>
    </div>

    <!-- Worked surface -->
    <div v-if="this.entriesChecked.length <= 1 && this.foundCropfield">
      <h4 class="subtitle-form mt-4" >Surface travaillée</h4>

      <b-container class="m-0 p-0">
        <b-row class="mb-2">
          <b-col cols="1"><input type="radio" ref="allCropfield" name="surface" @click="onChangeSurface()" /></b-col>
          <b-col cols="4"><span class="text-activity-notes subtitle-params"> Toute la parcelle </span></b-col>
          <b-col cols="2"><input type="radio" ref="manualEntry" class="input-margin" name="surface"  @click="onChangeSurface()" /></b-col>
          <b-col cols="4">
            <b-input
              type="text" :readonly="readonly"
              ref="workedsurface" v-model="workedSurface"
              size="sm" @click="onSelectManuelEntry()"
              :class="{'maxExceeded': workedsurfaceIsExceeded}"
              v-b-tooltip.hover
              :title="workedsurfaceIsExceeded ? $t('activities.details.exceeded_surface_hover') : ''"
              required
            />
          </b-col>
        </b-row>
      </b-container>
    </div>

  </div>
</template>

<script>
import ActivityModule from "@/store/modules/activity";
import ActivityTypesModule from "@/store/modules/activityTypes";
import { SET_STATE_INPUT_TO_PATCH } from "@/store/modules/activity";
import CropfieldModule from "@/store/modules/cropfield";
import { mapGetters } from "vuex";
import { CLEAN_HARVEST } from "@/store/modules/harvestsStorage";
import EditActivityDate from "@/components/activity/edit/EditActivityDate.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import RewriteButton from "@/components/commons/RewriteButton.vue";

export default {
  name: "edit-infos-activity",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      cropFieldsUpdated: [],
      errActivityType: false,
      errWorkedSurface: false,
      errBigWorkedSurface: false,
      foundCropfield: "",
      workedSurface: "",
      readonly: true,
      page: this.useForm,
      needInputs: true,
      needTargets: true,
      code: "",
      designation: "",
      errSelectCropField: false,
      activityType: "",
      growthStage: "",
      cropfield: "",
      selectedActivityType: {},
      selectedCropfield: {},
      loadComponent: false,
      resetedActivity: false,
      resetedCropfield: false
    };
  },
  components: {
    EditActivityDate,
    Dropdown,
    RewriteButton
  },
  created() {
    this.init();
  },

  methods: {
    init: async function() {
      this.loadComponent = false;
      await this.$store.dispatch("getAllActivityTypes");
      //if (Object.keys(this.currentEntry) == 0) return;
      const checkActivityType = element => element.designation == this.currentEntry.activityType_designation;
      const checkGrowStage = element => element.designation == this.currentEntry.growthStage_designation;

      if (this.$route.name.includes("activity.new") || !(['RTB', 'TRK'].includes(this.currentEntry.createdBy))) {
        await this.$store.dispatch("cropfield/fetchEntries", { filter: {} });
        this.cropFieldsUpdated = this.cropfields.map(obj=> ({ ...obj, name_surface_cropName: obj.name + " (" + obj.surface + " ha) " + obj.crop_name }))
        await this.$store.dispatch(this.storeEditAction, {createdBy: "UWA",});

      } else if(this.currentEntry.cropField.length > 0){
        // Récupère la liste des parcelles qui croisent celle actuelle sur les campagnes ouvertes
        await this.$store.dispatch("cropfield/getCrossesCropfieldsOnNearCampaigns", {id: this.currentEntry.cropField[0]});
        this.cropFieldsUpdated = this.crossesCropfields.map(obj=> ({ ...obj, name_surface_cropName: obj.name + " (" + obj.surface + " ha) " + obj.crop_name + " " + obj.campaign_name }))
      }

      if (this.$route.name === "activity.edit") {
        let index = this.activityTypes.findIndex(checkActivityType);
        this.activityType = this.currentEntry.activityType_designation != "" ? this.activityTypes[index]?.id : "";
        let activityType_code = this.currentEntry.activityType_designation != "" ? this.activityTypes[index]?.code : "";
        this.selectedActivityType = this.currentEntry.activityType_designation != "" ? this.activityTypes[index] : null;
        this.needInputs = this.getActivityTypeAttribut(this.activityType, "needInputs");
        if(this.currentEntry?.cropField?.length <= 1){
          this.selectedCropfield = this.cropFieldsUpdated.find(cf => cf.id === this.currentEntry.cropField[0]);
        }
        if (
          this.currentEntry.activityType_designation != "Transport" &&
          this.currentEntry.hasOwnProperty("growthStage_designation") &&
          this.getNeedInputs()
        ) {
          // await this.$store.dispatch("activity/getInputForm", { id: this.currentEntry.id });
          index = this.inputForm.growthStages?.findIndex(checkGrowStage);
          if (index >= 0) {
            this.growthStage =
              this.currentEntry.growthStage_designation != "" ? this.inputForm.growthStages[index].id : "";
          }
        }
        if(this.currentEntry.cropField.length == 1) {
          this.cropfield = this.currentEntry.cropField[0];
          this.OnInitActivityType(activityType_code, this.cropfield);
        }
        if(this.entriesChecked.length <= 1){
          await this.$store
          .dispatch("cropfield/getOneCropfield", { id: this.currentEntry.cropField })
          .then(response => {
            this.foundCropfield = response;
          });
        }
        // this.changeInputForm(this.activityType, this.cropfield, false)
      }
      else {
        this.needInputs = true;
      }
      // Gère la sélection automatique de la parcelle, lorsqu'on vient du panneau de détail sur les parcelles
      if (this.defaultCropfieldId) {
        this.selectedCropfield = this.cropFieldsUpdated.find(cf => cf.id === this.defaultCropfieldId);
        this.cropfield = this.defaultCropfieldId.toString();
      }

      if(this.entriesChecked && this.entriesChecked.length <= 1 && !this.$route.name.includes("activity.new") ) this.onChangeSurface();
      this.loadComponent = true;
    },
    OnInitActivityType(activityType_code, cropField)
    {
      this.$store.dispatch(this.storeEditAction, {
        activityType: this.activityType,
        activityType_code: activityType_code,
        cropField: cropField
      });
    },
    /**
     * Description: Renvoie l'ID du type d'activité sélectionné.
     * @return {Number}
     */
    getActivityType() {
      if (this.activityType != "") return this.activityType;
      this.errActivityType = true;
      return null;
    },
    /**
     * Description: Renvoie l'état du type d'activité s'il prend des intrants.
     * @return {Boolean}
     */
    getInputNeeded() {
      let el = this.activityTypes.find(f => f.id == this.activityType);
      return el;
    },
    /**
     * Description: Renvoie de la surface travaillée.
     * @return {String}
     */
    getWorkedSurface() {
      if (this.$route.name.includes("activity.new")) this.foundCropfield = this.cropfields.find(element => element.id == this.getCropfield())
      if (this.$refs["manualEntry"].checked && this.workedSurface == "") {
        return null;
      }
      if (this.workedSurface == "") return this.foundCropfield.surface;
      return this.workedSurface;
    },

    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
    resetAllActivityType(){
      this.resetedActivity = true
      this.$store.dispatch(this.storeEditAction, {
        activityType: null,
        activityType_code: null,
      });
    },
    resetAllCropfield(){
      this.resetedCropfield = true
      this.$store.dispatch(this.storeEditAction, {
        cropfield: null,
      });
    },

    /**
     * Description: Retourne un boolean informant si l'activité a besoin  d'inputs ou non.
     * @return {Boolean}  True si des inputs sont nécessaires sinon false.
     */
    getNeedInputs() {
      return this.needInputs;
    },

    /**
     * Description: Renvoie l'ID du growthStage sélectionné.
     * @return {Number}
     */
    getGrowthStage() {
      if (this.growthStage != "") return this.growthStage;
      return null;
    },

    /**
     * Description: Renvoie l'ID du cropfield sélectionné.
     * @return {Number}
     */
    getCropfield() {
      if (this.cropfield != "") return this.cropfield;
      return null;
    },

    /**
     * Description: Renvoie l'attribut d'un type activité selon le nom de l'attribut de comparaison renseigné
     * (l'ID par defaut) et la valeur de celui-ci.
     * @param {}            attrValue La valeur de l'attribut que l'on connaît.
     * @param {String}      attrResearched Le nom de l'attribut que l'on recherche.
     * @param {String}      attrCmp Le nom de l'attribut par lequel comparer la value de attrValue
     *                          (l'ID par défaut).
     * @return {}           La valeur de retour dépend de l'attribut recherché.
     */
    getActivityTypeAttribut(attrValue, attrResearched, attrCmp = "id") {
      let actType = this.activityTypes.filter(function(elem) {
        if (elem[attrCmp] == attrValue) return elem;
      });

      return actType[0]?.[attrResearched];
    },

    /**
     * Description: Vérifie si un champ a bien été saisie avant le type d'activité.
     */
    checkCropfield() {
      if (this.$route.name.includes("activity.new") && this.cropfield === "") {
        this.errSelectCropField = true;
        this.activityType = "";
        return false;
      } else if (this.$route.name.includes("activity.new") && this.activityType === "") return false;
      else this.errSelectCropField = false;

      return true;
    },

    /**
     * Appel les fonction relier au changement du cropfield.
     */
    async changeCropfield(value){
      this.changeInputForm("", value, false)
    },

    /**
     * Appel les fonction relier au changement du type d'activité.
     */
    async changeActivityType(value){
      this.changeInputForm(value, "", false)
      this.changeServiceForm(value)
    },

    /**
     * Description: Change le formulaire d'intrant lorsque le type d'activité est modifié.
     */
    async changeInputForm(valueActivityType, valueCropField, cleanInputs = true) {
      let inputFormMustBeRefreshed = false;
      if (valueActivityType != '' && this.activityType != valueActivityType) {
        inputFormMustBeRefreshed = true;
      }
      if (valueCropField != '' && this.cropfield != valueCropField) {
        inputFormMustBeRefreshed = true;
      }
      if (Object.keys(this.inputForm).length == 0) {
        inputFormMustBeRefreshed = true;
      }

      if( valueActivityType != '' ) this.activityType = valueActivityType;
      if( valueCropField != '' ){
        this.cropfield = valueCropField;
        let crop = this.cropFieldsUpdated.find(cropfield => cropfield.id == this.cropfield).crop
        this.$store.commit("activity/setEditedEntryValue", {cropField__crop: crop, cropField: valueCropField})
      }
      if(this.entriesChecked.length <= 1) this.onChangeSurface();
      if (!this.checkCropfield()) return;

      this.needInputs = this.getActivityTypeAttribut(this.activityType, "needInputs");
      this.needTargets = this.getActivityTypeAttribut(this.activityType, "needTargets");
      this.code = this.getActivityTypeAttribut(this.activityType, "code");
      this.designation = this.getActivityTypeAttribut(this.activityType, "designation");

      // Fournit les unités pour la saisie du rendement
      if (this.code == "recol") {
        await this.$store.dispatch("getNitrogenDoseUnits", { forYield: true });
      }

      this.growthStage = "";

      this.$store.commit("activity/" + SET_STATE_INPUT_TO_PATCH, cleanInputs);

      // new approach, as in other pages
      this.$store.commit("activity/setEditedEntryValue", {
        activityType: this.activityType,
        activityType_code: this.code,
        activityType_designation: this.designation,
        activityType_needInputs: this.needInputs,
        activityType_needTargets: this.needTargets,
      });

      if (this.needInputs && inputFormMustBeRefreshed) {
        await this.$store.dispatch("activity/getAllInputForm");

      } else if (this.currentEntry.activityType_designation == "Transport") {
        await this.$store.dispatch("getAllWarehouses");
        this.$store.commit(CLEAN_HARVEST, true);
      }
    },

    /**
     * Description: Change le formulaire de service lorsque le type d'activité est modifié.
     */
    async changeServiceForm(activityType){
      await this.$store.dispatch("getServices", {
            activityType: activityType,
            machines: this.currentEditedEntry?.machines,
      });
    },
    /**
     * Description: selectionner le button de saisi manuelle lorsque l'utilisateur met le curseur sur la zone de texte.
     */
    onSelectManuelEntry(){
      if(this.$refs["manualEntry"].checked != true)
       {
         this.$refs["manualEntry"].checked = true;
         this.workedSurface = "";
         this.readonly = false;
         if(this.$route.name.includes("activity.new") && this.cropfield != "") this.workedSurface = (this.cropfields.find(element => element.id == this.cropfield)).surface;
         else if(this.$route.name === "activity.edit") this.workedSurface = this.foundCropfield.surface;
         else this.workedSurface = "";
       }
    },

    /**
     * Description: Remplit la valeur de la surface travaillé.
     */
    async onChangeSurface() {
      if (this.$route.name.includes("activity.new")) {
        // Set foundCropfield first
        this.foundCropfield = this.cropfields.find(
          (element) => element.id == this.getCropfield()
        );

        this.$nextTick(async () => {
          // Wait for DOM update
          const allCropfieldInput = this.$refs["allCropfield"];
          const manualEntryInput = this.$refs["manualEntry"];

          if (allCropfieldInput.checked) {
            this.workedSurface = "";
            this.readonly = true;

            await this.$store.dispatch(this.storeEditAction, {
              surface: this.foundCropfield.surface,
            });
          } else if (this.cropfield && manualEntryInput.checked) {
            this.readonly = false;
            this.foundCropfield = this.cropfields.find(
              (element) => element.id == this.cropfield
            );
            this.workedSurface = this.foundCropfield?.surface || "";
          } else {
            allCropfieldInput.checked = true;
            this.readonly = true;

            if (this.foundCropfield) {
              await this.$store.dispatch(this.storeEditAction, {
                surface: this.foundCropfield.surface,
              });
            }
          }
        });
      }
      else if (this.$route.name === "activity.edit") {
        if(this.$refs["allCropfield"].checked)
        {
          this.workedSurface = ""
          this.readonly = true;
          await this.$store.dispatch(this.storeEditAction, {surface: this.foundCropfield.surface,});
        }
        else if(this.$refs["manualEntry"].checked)
        {
          this.readonly = false;
          this.workedSurface = this.currentEntry.surface;
          await this.$store.dispatch(this.storeEditAction, {surface: this.workedSurface});
        }
        else if(parseFloat(this.currentEntry.surface) == parseFloat(this.foundCropfield.surface))
        {
          this.readonly = true;
          await this.$store.dispatch(this.storeEditAction, {surface: this.foundCropfield.surface,});
          this.workedSurface = "";
          this.$refs["allCropfield"].checked = true;
        }
        else if(this.currentEntry.surface!=null)
        {
          this.readonly = false;
          this.workedSurface = this.currentEntry.surface;
          this.$refs["manualEntry"].checked = true;
          await this.$store.dispatch(this.storeEditAction, {surface: this.workedSurface});
        }

      }
    },
  },

  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
      if (!store.state["cropfield"]) {
        store.registerModule("cropfield", CropfieldModule);
      }
      if (!store.state["activityTypes"]) {
        store.registerModule("cropfield", ActivityTypesModule);
      }
    }
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      inputForm: "activity/inputForm",
      cropfields: "cropfield/entries",
      activityTypes: "activityTypes",
      fingerprints: "activity/fingerprints",
      crossesCropfields: "cropfield/crossesCropfieldsOnNearCampaigns",
      defaultCropfieldId: "activity/selectedCropfieldId",
      entriesChecked: "activity/entriesChecked",
      currentEditedEntry: "activity/editedEntry",
      activityTypeGroups: "activityTypeGroups",
    }),
    activityLoaded() {
      return this.$store.getters["activity/entryLoaded"];
    },
    /**
     * Renvoie un array pris de this.cropfields + surchargé avec un attribut
     * name_surface_cropName qui va être util dans le DropDown
     */
    getCropFiedlsUpdated()
    {
      this.cropFieldsUpdated = this.cropfields.map(obj=> ({ ...obj, name_surface_cropName: obj.name + " (" + obj.surface + " ha) " + obj.crop_name }))
      return this.cropFieldsUpdated;
    },
    /**
     * Retourne true lorsque la surface travaillée est supérieure à la surface de la parcelle
     */
    workedsurfaceIsExceeded() {
      if (
        parseFloat(this.workedSurface) > parseFloat(this.foundCropfield?.surface) &&
        parseFloat(this.workedSurface) > parseFloat(this.foundCropfield?.calculatedSurface)
      ) {
        return true
      }
      return false
    },
    activityTypesFiltered() {
      // we filter by activity type family
      // two cases :
      // 1. it's in currentEditedEntry, for example when we create a new tour
      // 2. otherwise, we take the family of the selected value
      const groupCode = this.currentEditedEntry.groupCode

      // if no family or we are duplicating a tour, return complete list
      if(!groupCode || this.$route.name === "activity.edit") return this.activityTypes

      return this.activityTypes.filter(t => t.activityTypeGroups.includes(groupCode));
    },
  },
  watch: {
    workedSurface: {
      handler: async function() {
        if(this.getWorkedSurface() !=null) await this.$store.dispatch(this.storeEditAction, {surface: this.getWorkedSurface(),});
      }
    },
    cropfield: {
      handler: async function() {
        let crop = this.cropFieldsUpdated.find(cropfield => cropfield.id == this.cropfield)?.crop;
        await this.$store.dispatch(this.storeEditAction, {cropField: this.cropfield, cropField__crop: crop});
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/**
 * Parameter error cropfield.
 */
.error-select-cropfield {
  // text-align: center;
  color: $danger-color;
}

/**
 * Inputs params.
 */
.inputs-params {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

/**
 * Inputs margin.
 */
.input-margin {
  margin-left: 30px;
}

/**
 * Subtitle params.
 */
.subtitle-params {
  margin: auto 0px 2px 0px;
}

/**
 * Parameter error worked surface.
 */
.error-worked-surface {
  // text-align: center;
  color: $danger-color;
}

/**
 * Inputs params.
 */
.inputs-params {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

/**
 * Resize rows.
 */
.row-resize{
    padding-left: 16px;
}

.maxExceeded {
  border-color: $warning-color;
  background-color: #F09D0B2F;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}

.text-reset {
    font-size: 14px;
    padding: 5px;
    color: #222e50;
    margin: 5px;
}
</style>
